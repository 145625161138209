import React from "react";
import {Trans, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import {createMarkup} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";

import CarouselWrapper from '../../../components/carousel/CarouselWrapper';
import Project01 from './images/project/09-2-project-01a.jpg';
import Project02 from './images/project/09-2-project-02a.jpg';
import Project03 from './images/project/09-2-project-03a.jpg';
import Project04 from './images/project/09-2-project-04a.jpg';
import Project05 from './images/project/09-2-project-05a.jpg';
import Project06 from './images/project/09-2-project-06a.jpg';
import staffLockers from './images/applications/staff-locker.jpg';
import toolStorage from './images/applications/tool-store.jpg';
import juice from './images/applications/juice.jpg';
import CustomerVideo from "../../../components/solutions/CustomerVideo";
import posterSolution from "./images/poster-manufacturing.jpg";

const IndexPage = () => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const projectImages = [
    {
      original: Project01,
      originalAlt: 'Polycarbonate Electronic Lockers Secured with Digilock Cue at California Tesla Factory',
      thumbnailAlt: 'Polycarbonate Electronic Lockers Secured with Digilock Cue at California Tesla Factory thumbnail',
      thumbnail: Project01,
      thumbnailLabel: 'Tesla',
      productLink: returnLocaleURL(sl, '/products/')

    },
    {
      original: Project02,
      originalAlt: 'Versa Electronic Locks Installed on Employee Lockers at Vreugdenhil Dairy Foods',
      thumbnailAlt: 'Versa Electronic Locks Installed on Employee Lockers at Vreugdenhil Dairy Foods thumbnail',
      thumbnail: Project02,
      thumbnailLabel: 'Vreugdenhil Dairy Foods',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project03,
      originalAlt: 'Huybregts RFID lockers',
      thumbnailAlt: 'Huybregts RFID lockers thumbnail',
      thumbnail: Project03,
      thumbnailLabel: 'Huybregts',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project04,
      originalAlt: 'Bohemilk RFID lockers',
      thumbnailAlt: 'Bohemilk RFID lockers thumbnail',
      thumbnail: Project04,
      thumbnailLabel: 'Bohemilk',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project05,
      originalAlt: 'Fitbit keypad lockers',
      thumbnailAlt: 'Fitbit keypad lockers thumbnail',
      thumbnail: Project05,
      thumbnailLabel: 'Fitbit',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project06,
      originalAlt: 'Volkswagen RFID lockers',
      thumbnailAlt: 'Volkswagen RFID lockers thumbnail',
      thumbnail: Project06,
      thumbnailLabel: 'Volkswagen',
      productLink: returnLocaleURL(sl, '/products/numeris/aspire/')
    }
  ];
  const projectAsides = [
    '<span>united states</span><br>' +
    '<h2>Tesla</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Cue Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Vreugdenhil Dairy Foods</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa Standard RFID</a></p>',

    '<span>THE netherlands</span><br>' +
    '<h2>Huybregts</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis RFID</a></p>',

    '<span>The Czech Republic</span><br>' +
    '<h2>Bohemilk</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Axis RFID</a></p>',

    '<span>ireland</span><br>' +
    '<h2>Fitbit</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>china</span><br>' +
    '<h2>Volkswagen</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Aspire RFID</a></p>'
  ];
  const ProjectAside = _ => <aside className={'dynamic-aside'}
                                   dangerouslySetInnerHTML={createMarkup(projectAsides[0])} />;
  const solutions = [
    {
      img: staffLockers,
      label: 'retail_locker_rooms',
      alt: 'staff_lockers',
      bg: 'light'
    },
    {
      img: toolStorage,
      label: 'tool_storage',
      alt: 'tool_storage',
      bg: 'dark'
    },
    {
      img: juice,
      label: 'juicebar_charging_lockers',
      alt: 'juicebar_charging_lockers',
      bg: 'light'
    }
  ]

  return (
      <Layout>
        <Seo title={t('solutions_manufacturing')}
             description={t('des_solutions_manufacturing')}/>
        <div className="solutions">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-manufacturing.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Manufacturing"
            />
            <h1>
              <Trans>
                manufacturing
              </Trans>
              <span>
                <Trans>
                  manufacturing_hero_subhead
                </Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>manufacturing_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>manufacturing_subhero_list_l1</Trans></li>
                  <li><Trans>manufacturing_subhero_list_l2</Trans></li>
                  <li><Trans>manufacturing_subhero_list_l3</Trans></li>
                  <li><Trans>manufacturing_subhero_list_l4</Trans></li>
                  <li><Trans>manufacturing_subhero_list_l5</Trans></li>
                  <li><Trans>manufacturing_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/manufacturing.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="manufacturing"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup label={'manufacturing_lineup_label'} showPivot={true} showCurve={false} showAspire={true} showVersa={true} showCTA={true} />
          <section className="industry-carousel">
            <div className="container">
              <h2>
                <Trans>
                  manufacturing_carousel
                </Trans>
              </h2>
              <CarouselWrapper
                  items={projectImages}
                  asides={projectAsides}
                  projectAside={<ProjectAside />}
                  showThumbnails={true}
                  showBullets={true}
                  dynamicAside={true}
                  allowImageClick={false}
              />
            </div>
          </section>
          <section className="customer-video healthcare">
            <CustomerVideo
                poster={posterSolution}
                title={'manufacturing_customer_video_title'}
                copy={'manufacturing_customer_video_copy'}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/PBTxgb5kEUqfQEqjYVKRpw/7rG5zkkSNkC2fFFuYuy7-A/Widescreen720p/manufacturing.mp4'}
            />
          </section>
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless</Trans></h2>
              <Solution items={solutions} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
